/* Bootstrap Alerts */

.alert {
  font-size: $default-font-size;
  i {
    font-size: 1.25rem;
    margin-right: 1.25rem;
    vertical-align: middle;
    line-height: .5;
  }
}

@each $color, $value in $theme-colors {
  .alert-#{$color} {
    @include alert-variant($value);
  }
}

@each $color, $value in $theme-colors {
  .alert-fill-#{$color} {
    @include alert-variant-fill($value);
  }
}
 