/* Tabs */

.add-items {
  margin-bottom: 1.5rem;
  overflow: hidden;
  input[type="text"] {
    border-radius:5px;
    width: calc(100% - 100px);
    padding: 0.4875rem;
    border: 1px solid $border-color;
  }
  .btn {
    margin-left: .5rem;
    font-weight: 600;
    font-size: 0.6875rem;
    padding: 0.6875rem 1rem;
    .rtl & {
      margin-left: auto;
      margin-right: .5rem;
    }
  }
}

.list-wrapper {
  height: 100%;
  max-height: 100%;
  ul {
    padding: 0;
    text-align: left;
    list-style: none;
    margin-bottom: 0;
    li {
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-start;
      font-size: .9375rem;
      padding: .4rem 0;
      border-bottom: 1px solid $border-color;
      &:first-child {
        border-bottom: none;
      }
      .form-check{
        max-width: 90%;
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-right: 1rem;
        .form-check-label{
        }
      }
    }
  }

  input[type="checkbox"] {
    margin-right: 15px;
  }

  .remove {
    @extend .ms-auto;
    @extend .text-secondary;
    .rtl & {
      @extend .ms-0;
      @extend .me-auto;
    }
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 600;
    width: 1.875rem;
    height: 1.875rem;
    line-height: 20px;
    text-align: center;
    padding-top: 4px;
    flex-shrink: 0;
  }
  .completed {
    text-decoration: line-through;
    text-decoration-color: $info;
    .remove{
      background: lighten($danger, 35%);
      border-radius:100%;
      @media(max-width: 767px) {
        flex-shrink: 0;
      }
      &:before{
      color: $danger;
      }
    }
  }
}